<script>

import {GameType} from "../gameCommon";

export default {
  name: "HandHistoryItem",
  props: {
    gameType: Number,
    gameTitle: String,
    smallBlind: String,
    bigBlind: String,
    firstPlayTime: String,
    playCount: Number,
    balanceChange: Number,
    rank: Number,
    prize: Number,
  },
  computed: {
    isRelayGame(){
      return this.gameType === GameType.Relay;
    },
    isTournamentGame(){
      return this.gameType === GameType.Tournament;
    }
  }
}
</script>

<template>
  <div class="item_history" @click.prevent.stop="$emit('modal', 'handhistorydetails')">
    <div class="area_left">
      <div v-if="isRelayGame">
        <span class="type">홀덤</span>
        <span class="tit">{{ gameTitle }}<span class="b_num">({{ smallBlind }}/{{ bigBlind }})</span></span>
      </div>
      <div v-if="isTournamentGame">
        <span class="type">토너먼트</span>
        <span class="tit">{{ gameTitle }}</span>
      </div>
      <div class="play_info">
        <div class="play_num"><span class="ico material-symbols-rounded">play_circle</span>플레이 횟수 {{ playCount }}회</div>
        <div v-if="isTournamentGame && prize > 0" class="rank ms-2"><span class="ico material-symbols-rounded">star</span><span>순위 <span class="yellow">{{rank}}</span>위</span></div>
        <div v-if="isTournamentGame && !prize" class="rank ms-2"><span class="ico material-symbols-rounded">star</span><span><span class="yellow">탈락</span></span></div>
      </div>
    </div>
    <div class="area_right">
      <div class="time_start">시작시간 {{ firstPlayTime }}</div>
      <div v-if="isRelayGame" class="v_coin v_num" :class="{ l_num: balanceChange < 0 }">{{ balanceChange | formatCash }}</div>
      <div v-if="isTournamentGame" class="v_coin v_num">{{ prize | formatCash }}</div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>